import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd">
          <path d="M6319 9536 c-2 -2 -33 -6 -68 -10 -48 -4 -67 -10 -78 -26 -8 -11 -20
-20 -26 -19 -9 0 -9 2 1 6 22 9 13 25 -10 17 -103 -31 -236 -114 -317 -199
-44 -45 -52 -58 -40 -66 11 -8 10 -9 -6 -7 -16 2 -29 -15 -67 -92 -26 -52 -55
-122 -63 -155 -63 -243 6 -500 185 -685 143 -148 316 -221 525 -220 292 1 531
158 660 432 54 117 79 299 54 406 -25 114 -34 141 -67 207 -52 105 -176 256
-199 242 -5 -3 -15 -1 -23 5 -12 8 -11 9 4 4 19 -7 19 -6 2 13 -23 25 -194
111 -222 111 -11 0 -24 4 -29 9 -14 12 -206 36 -216 27z m-220 -80 c-2 -2 -17
-6 -34 -10 -22 -5 -26 -4 -15 4 14 9 59 15 49 6z m-114 -56 c-3 -5 -11 -10
-16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m855 -85 c11 -13 10
-14 -4 -9 -9 3 -16 10 -16 15 0 13 6 11 20 -6z m-960 1 c0 -2 -7 -7 -16 -10
-8 -3 -12 -2 -9 4 6 10 25 14 25 6z m1041 -109 c-10 -9 -11 -8 -5 6 3 10 9 15
12 12 3 -3 0 -11 -7 -18z m92 -139 c6 -22 5 -22 -8 -4 -17 22 -20 40 -6 32 5
-3 11 -16 14 -28z"/>
<path d="M5070 8756 c-563 -191 -1017 -522 -1376 -1001 -293 -391 -483 -884
-534 -1387 -16 -157 -8 -549 14 -688 75 -464 249 -886 515 -1250 100 -137 278
-340 297 -340 11 0 195 192 202 210 2 4 -18 29 -43 56 -355 376 -574 815 -666
1339 -29 162 -36 507 -15 685 26 221 85 450 176 680 69 175 231 446 363 610
61 74 280 299 347 356 123 103 302 220 455 298 151 76 190 93 343 147 119 42
123 45 118 69 -4 14 -21 77 -39 141 l-33 117 -124 -42z"/>
<path d="M7447 8397 c-81 -123 -117 -181 -117 -188 0 -4 57 -49 128 -100 317
-231 626 -612 785 -970 l38 -86 97 -7 c53 -3 127 -9 164 -12 l67 -6 -31 83
c-82 217 -293 584 -423 734 -11 13 -52 60 -92 106 -132 155 -413 400 -551 481
l-30 18 -35 -53z"/>
<path d="M5855 7694 c-505 -36 -967 -306 -1241 -726 -41 -63 -59 -96 -123
-224 l-20 -42 74 -31 c41 -17 104 -42 140 -56 l65 -24 45 90 c193 390 557 644
1016 710 163 23 404 -5 583 -67 38 -13 71 -24 72 -24 1 0 17 35 34 78 18 42
40 95 50 117 33 72 32 75 -38 101 -189 71 -460 112 -657 98z"/>
<path d="M6741 7442 c-109 -54 -135 -201 -51 -289 34 -37 82 -57 135 -57 60 0
105 23 148 77 27 32 31 45 31 100 1 53 -3 70 -27 105 -49 75 -156 104 -236 64z"/>
<path d="M5636 6648 c5 -7 117 -178 251 -381 l243 -367 0 -265 0 -265 120 0
120 0 1 233 c0 127 1 248 3 267 2 23 21 61 57 112 30 43 79 114 109 158 69
101 182 264 273 395 38 55 72 105 74 112 4 10 -28 13 -143 13 l-149 0 -114
-178 c-63 -97 -137 -214 -165 -259 -28 -45 -55 -82 -61 -83 -5 0 -82 117 -170
260 l-160 260 -148 0 c-116 0 -147 -3 -141 -12z"/>
<path d="M7500 6016 l0 -645 120 -3 120 -3 0 263 0 262 318 -2 317 -3 3 -257
2 -258 120 0 120 0 0 645 0 645 -120 0 -120 0 -2 -271 -3 -270 -285 0 c-157 0
-300 0 -317 1 l-33 0 0 270 0 270 -120 0 -120 0 0 -644z"/>
<path d="M9840 6646 c-242 -52 -415 -210 -490 -451 -30 -96 -38 -299 -16 -405
20 -95 81 -220 141 -287 123 -139 335 -213 567 -200 168 10 297 63 410 167
l63 57 3 254 c2 140 0 264 -3 276 l-5 23 -275 0 -275 0 0 -110 0 -110 165 0
166 0 -3 -117 -3 -117 -40 -27 c-67 -44 -125 -61 -221 -67 -147 -8 -268 33
-352 120 -184 188 -145 558 75 709 147 102 380 95 535 -16 l52 -38 72 79 c51
55 70 82 63 90 -6 7 -42 34 -82 61 -70 49 -116 71 -207 100 -65 20 -260 26
-340 9z"/>
<path d="M4405 6441 c-178 -47 -272 -249 -192 -413 30 -61 74 -105 140 -140
62 -33 193 -33 257 1 103 54 164 158 162 277 -1 108 -70 212 -171 257 -49 21
-148 31 -196 18z"/>
<path d="M4563 5699 c-72 -20 -136 -40 -141 -43 -6 -3 3 -44 23 -98 150 -432
493 -787 922 -957 202 -80 365 -111 588 -111 198 0 337 21 505 77 122 41 117
24 55 174 -29 72 -55 133 -58 135 -3 3 -47 -8 -99 -25 -51 -16 -141 -37 -200
-45 -425 -63 -832 73 -1134 379 -129 131 -270 362 -305 500 -6 25 -14 47 -18
48 -3 2 -65 -13 -138 -34z"/>
<path d="M6728 5040 c-49 -15 -86 -49 -104 -96 -50 -131 80 -255 209 -200 97
41 126 169 55 245 -50 53 -100 69 -160 51z"/>
<path d="M8176 4911 c-14 -22 -26 -43 -26 -45 0 -10 -86 -144 -148 -231 -97
-135 -160 -210 -274 -323 -584 -577 -1393 -831 -2203 -692 -185 32 -387 94
-610 186 -6 2 -16 -10 -24 -28 -8 -18 -37 -80 -63 -137 -27 -57 -48 -105 -46
-106 10 -10 269 -109 344 -131 430 -129 896 -159 1315 -83 451 81 854 256
1214 528 134 101 150 115 295 261 149 150 233 252 346 422 91 136 219 372 210
386 -3 5 -63 12 -133 16 -71 4 -138 10 -150 13 -17 4 -27 -4 -47 -36z"/>
<path d="M4355 4197 c-148 -50 -224 -143 -233 -287 -3 -60 0 -86 18 -132 24
-65 87 -136 148 -167 97 -50 226 -42 321 18 137 88 182 282 98 420 -38 62
-113 126 -167 142 -58 17 -142 20 -185 6z"/>
</g>
</svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
